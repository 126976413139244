@import "./variables";

// globals:

*,
*:before,
*:after {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
body {
  font-family: $sansStack;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $textColor;
}
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
p {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
a {
  text-decoration: none;
  color: inherit;
}
ul {
  padding-left: 1.2em;
}
address {
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

// clearfix helper class:
.cf:before,
.cf:after {
  content: " ";
  display: table;
}
.cf:after {
  clear: both;
}
