@import "./variables";

.work {
  margin: 0 auto;
  padding-top: 80px;
  max-width: 1400px;
  text-align: center;

  .headnote {
    display: inline-block;
    padding: 0 20px 10px;
    margin: 0 auto 40px;
    border-bottom: 1px solid $lightBorderColor;
    font: 12px/1 $work;
    text-transform: uppercase;
    letter-spacing: 2px;

    @media ($laptop) {
      font-size: 15px;
    }
  }
}

.site {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 0 30px;
  margin: 0 auto 40px;
  max-width: 450px;

  @media ($tablet) {
    min-height: 300px;
  }

  img {
    max-width: 100%;
    border: 1px solid $borderColor;
    transition: all 0.3s;
  }

  &.inactive {
    &:after {
      content: "Site has since been redone 😢";
      opacity: 0;
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      padding: 10px 15px;
      border-radius: 4px;
      background: white;
      box-shadow: 0 0 6px rgba(black, 0.4);
      font-size: 13px;
      transition: all 0.3s;
    }

    &:hover {
      cursor: not-allowed;

      &:after {
        opacity: 1;
        top: 40%;
      }
    }
  }

  a {
    display: inline-block;

    img {
      @media ($tablet) {
        transform: scale(1);
        box-shadow: 0 4px 10px transparent;

        &:hover,
        &:focus {
          transform: scale(1.3);
          box-shadow: 0 4px 10px rgba(black, 0.25);
        }
      }
    }
  }

  h4 {
    font: 18px/1 $trocchi;
    margin: 0.75em auto 0.25em;
    color: $deepBlue;
  }

  p {
    font-size: 12px;
    line-height: 1.4;
    color: $altTextColor;
  }
}
