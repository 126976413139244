@import "./variables";

.top-ribbon {
  text-align: center;
  padding: 4px;
  background: #ebd596;
  color: #071e36;

  p {
    margin: 0;
    font-size: 14px;
  }

  a {
    font-weight: bold;
    text-decoration: underline;
  }
}
