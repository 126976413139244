@import "./variables";

.about-me {
  background: $deepBlue url("../../public/code.jpg") no-repeat 20% top;
  background-attachment: local; // fallback for browsers that don't support fixed
  background-attachment: fixed;
  background-size: cover;
}

.about-content {
  text-align: center;
  padding: 55px 15px 35px;
  font-size: 14px;
  line-height: 2;
  color: rgba(white, 0.8);
  background: rgba($deepBlue, 0.92);

  h1 {
    font: 40px/1 $trocchi;
  }

  h2 {
    font-size: 22px;
  }

  .intro {
    br {
      @media ($iphone6) {
        display: none;
      }
    }

    a {
      position: relative;
      display: inline-block;
      cursor: pointer;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 1px;
        background: rgba(white, 0.8);
        transition: width 0.2s;
      }

      &:hover:after {
        width: 100%;
      }
    }
  }
}

.contact-info {
  margin: 0;
  padding: 0;
  list-style: none;

  @media ($iphone6) {
    width: 90%;
    max-width: 900px;
    margin: 0 auto;
  }

  a {
    display: inline-block;
    transition: transform 0.2s;

    &:hover,
    &:focus {
      transform: translateY(-5px);
    }
  }

  img {
    display: block;
    margin: 1em auto 0.4em;
    font-size: 1.8em;
  }

  li {
    line-height: 1.3;

    @media ($iphone6) {
      float: left;
      width: 25%;
    }
  }

  br {
    display: none; // hide on mobile

    @media ($iphone6) {
      display: inline; // normal behavior
    }
  }
}

p.speech {
  background: rgba(white, 0.3);
  position: relative; // contain abs-pos :after element
  display: inline-block;
  padding: 5px 15px;
  margin-bottom: 20px;
  border-radius: 20px;
  transform-origin: center bottom;
  animation: showWordBubble 0.4s 0.6s;
  animation-fill-mode: both;

  &:after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -10px; // match border-top size
    width: 0;
    height: 0;
    margin: 0 auto;
    border-left: 2px solid transparent;
    border-right: 7px solid transparent;
    border-top: 10px solid $ashBlue; // fallback color
    border-top: 10px solid rgba(white, 0.3);
  }
}

p.scroll-down {
  font-size: 18px;
  margin-top: 1em;

  a img {
    cursor: pointer;
    transition: transform 0.2s;
  }

  a:hover img,
  a:focus img {
    transform: translateY(5px);
  }
}

@keyframes showWordBubble {
  0% {
    transform: scale(0) translateY(-50px) rotate(45deg);
  }
  100% {
    transform: scale(1) translateY(0) rotate(0deg);
  }
}
