@import "./variables";

.skills {
  padding: 60px 15px;
  text-align: center;

  h2 {
    @include sectionHeadingStyles;
  }
}

.skills-items {
  list-style: none;
  max-width: 850px;
  margin: 0 auto;
  padding: 0;

  li {
    display: inline-block;
    padding: 15px;
    font-size: 14px;
    line-height: 1;
    color: $lightTextColor;
  }

  a {
    transition: color 0.3s;

    &:hover,
    &:focus {
      color: $textColor;
    }
  }
}
