@import "./variables";

.cta {
  background: $deepBlue url("../../public/ocean.jpg") no-repeat center center;
  background-size: cover;
  background-attachment: local; // fallback for browsers that don't support fixed
  background-attachment: fixed;
}

.cta-content {
  text-align: center;
  padding: 100px 15px;
  color: white;
  background: rgba($deepBlue, 0.8);

  p {
    font: 24px/1.4 $trocchi;
    margin-bottom: 1.5em;
  }
}

.cta-button {
  display: inline-block;
  margin: 0 auto;
  padding: 15px 35px;
  border: 2px solid white;
  border-radius: 50px;
  background-color: transparent;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover,
  &:focus {
    background: white;
    color: #0c3663;
    outline: none;
  }
}
