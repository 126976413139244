@import "./variables";

.jobs {
  width: 100%;
  max-width: 1087px; // 544 * 2 - 1
  margin: 0 auto 60px;
}

.headnote-container {
  width: 100%;
  max-width: 544px;
  margin: 0 auto 20px;
  padding: 0 15px;

  @media ($tablet) {
    margin-bottom: 40px;
  }

  @media ($laptop) {
    width: 130px;
    margin: 0 auto;
    padding: 0;
    text-align: center;
  }

  .headnote {
    @include headnoteStyles;

    @media ($laptop) {
      display: block;
      margin: -18px auto 10px;
      padding: 10px 0;
      background: white;
      border: 1px solid $lightBorderColor;
      border-top: none;
      border-bottom: none;
      font-size: 15px;
    }
  }
}

.job {
  width: 100%;
  max-width: 544px;
  margin: 0 auto 60px;
  padding: 0 15px;

  @media ($laptop) {
    margin-bottom: 0;

    &:nth-of-type(1) {
      padding-top: 50px; // let line extend above first job
    }

    &:nth-last-of-type(1) {
      padding-bottom: 50px; // let line extend below last job
    }

    // create a divider line down the page and float jobs to alternating sides:

    &:nth-of-type(2n + 1) {
      float: left;
      padding-right: 20px;
      border-right: 1px solid $borderColor;

      .job-details {
        text-align: right;

        &:after {
          left: auto;
          right: -34px;
        }
      }
    }

    &:nth-of-type(2n) {
      float: right;
      padding-left: 20px;
      border-left: 1px solid $borderColor;

      .job-details {
        float: left;

        &:after {
          left: -34px;
          right: auto;
        }
      }

      .job-specs {
        float: right;
        text-align: right;
        margin-right: 0;
      }

      .job-icon {
        float: none;
        margin-right: 0;
      }
    }
  }
}

// job details group:

.job-details {
  @media ($tablet) {
    position: relative; // contain abs-pos'd :after element
    float: right;
    width: 75%;
  }

  @media ($laptop) {
    // add dots along the divider line, like a timeline:
    &:after {
      content: " ";
      position: absolute;
      top: 21px;
      width: 26px;
      height: 26px;
      background: $salmon;
      border-radius: 50%;
      border: 8px solid white; // color matches page bg color
    }
  }
}

.job-title {
  font: 30px/1 $trocchi;
  color: $deepBlue;
  margin-bottom: 0.3em;
}

.job-employer {
  font: 300 24px/1.2 $work;
  margin-bottom: 0.3em;
  text-transform: uppercase;
  letter-spacing: -0.5px;
}

.job-summary {
  font: 14px/1.5 $trocchi;

  ul {
    list-style: none;
    margin-top: 0.5em;
    padding: 0.5em 0 0;
    border-top: 1px solid $lightBorderColor;
  }

  li {
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    border-bottom: 1px solid $lightBorderColor;
  }
}

// job specs group:

.job-specs {
  @media ($tablet) {
    position: relative;
    float: left;
    width: 21%;
    margin-right: 4%;
  }
}

.job-specs br {
  display: none; // hide on mobile

  @media ($tablet) {
    display: inline; // normal behavior
  }
}

.job-icon {
  float: left;
  width: 75px;
  height: 75px;
  margin-right: 15px;

  @media ($tablet) {
    margin: -10px 100% 20px 0;
  }
}

.job-dates,
.job-location,
.job-industry {
  font-size: 12px;
  line-height: 1.4;
  color: $altTextColor;
  margin-bottom: 0.25em;

  @media ($tablet) {
    margin-bottom: 0.5em;
  }
}

.job-dates {
  margin-top: 10px;
}
