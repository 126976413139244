@import "./variables";

.current-job {
  max-width: 544px;
  margin: 0 auto 60px;
  padding: 50px 15px 0;

  @media ($laptop) {
    max-width: 1087px; // matches .jobs, allows bottom border to extend
    padding: 50px 190px 60px; // keep text in narrower column
    margin-bottom: 0;
    text-align: center;
    border-bottom: 1px solid $borderColor;
  }
}

.headnote {
  @include headnoteStyles;
  margin-bottom: 10px;

  @media ($laptop) {
    font-size: 15px;
    padding-left: 20px; // balance left-right
    margin: 0 auto 10px;
  }
}

.icon {
  display: block;
  width: 100px;
  margin-bottom: 10px;

  @media ($laptop) {
    width: 150px;
    margin: 0 auto 20px;
  }
}

.title {
  font: 30px/1 $trocchi;
  color: $deepBlue;
  margin-bottom: 0.3em;
}

.employer {
  font: 300 24px/1.2 $work;
  margin-bottom: 0.3em;
  text-transform: uppercase;
  letter-spacing: -0.5px;

  @media ($laptop) {
    margin-bottom: 20px;
  }
}

.specs {
  margin: 10px auto;

  @media ($laptop) {
    margin-bottom: 20px;
  }

  p {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 0.25em;
    font-size: 12px;
    line-height: 1.4;
    color: $altTextColor;

    @media ($tablet) {
      margin-bottom: 0.5em;
    }
  }
}

.summary {
  font: 14px/1.5 $trocchi;

  @media ($laptop) {
    width: 90%;
    margin: 0 auto;
    max-width: 700px;
  }

  ul {
    list-style: none;
    margin-top: 0.5em;
    padding: 0.5em 0 0;
    border-top: 1px solid $lightBorderColor;
  }

  li {
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    border-bottom: 1px solid $lightBorderColor;
  }
}
